<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div id="cartFull" v-if="carts.length > 0">
        <div v-for="(data, index) in carts" :key="index">
          <b-card-header>
            <b-card-title>{{ data.cart.priceType }}</b-card-title>
          </b-card-header>
          <b-card no-body class="mb-0">
            <b-table
              :striped="true"
              :bordered="true"
              :hover="true"
              ref="refUserListTable"
              class="position-relative"
              :items="data.details"
              responsive
              :fields="tableColumns"
              primary-key="id"
              sort-by.sync="id"
              show-empty
              empty-text="Eşleşen ürün bulunamadı."
              head-variant="dark"
            >
              <template #cell(product.coverImgUrl)="data">
                 <div class="item-quantity d-flex justify-content-center">
                  <b-badge
                    variant="light-success"
                    v-if="data.item.product.isCampaign"
                  >
                    Kampanyalı Ürün
                  </b-badge>
                </div>
                <div class="item-quantity d-flex justify-content-center">
                  <b-img
                    height="120"
                    :src="data.item.product.coverImgUrl"
                    alt="data.item.product.caption')"
                  />
                </div>
               
              </template>
              <template #cell(productUnit)="data">
                <div class="item-quantity d-flex justify-content-center">
                  <b-form-spinbutton
                    v-model="data.item.productUnit"
                    size="sm"
                    min="0"
                    max="100000000"
                    @change="changeProductUnit(data)"
                    :step="data.item.product.packageUnit"
                    class="ml-75"
                    inline
                  />
                </div>
              </template>
              <template #cell(product.priceFormatted)="data">
                <div class="item-quantity d-flex justify-content-end">
                  {{ data.item.product.priceFormatted }}
                  {{ data.item.product.priceType }}
                </div>
              </template>
              <template #cell(totalPriceFormatted)="data">
                <div class="item-quantity d-flex justify-content-end">
                  {{ data.item.totalPriceFormatted }}
                  {{ data.item.product.priceType }}
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="item-quantity d-flex justify-content-center">
                  <b-button
                    style="margin-top: 0x"
                    type="button"
                    variant="primary"
                    @click="deleteCardProduct(data)"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span class="align-middle">Sil</span>
                  </b-button>
                </div>
              </template>
              <template #cell(discountPriceFormatted)="data">
                <div class="item-quantity d-flex justify-content-end">
                  {{ data.item.discountPriceFormatted }}
                  {{ data.item.product.priceType }}
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <!-- <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          <strong>Toplam Tutar:</strong>
                        </td>
                        <td
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                          {{ data.totalPriceFormatted }}
                          {{ data.cart.priceType }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <strong> İskontolar Toplamı:</strong>
                        </td>
                        <td
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                          {{ data.discountPriceFormatted }}
                          {{ data.cart.priceType }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <strong> İskontolu Toplam Tutar:</strong>
                        </td>
                        <td
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                          {{ data.netTotalPriceFormatted }}
                          {{ data.cart.priceType }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <strong> KDV'li Toplam Tutar:</strong>
                        </td>
                        <td
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                          {{ data.overallPriceFormatted }}
                          {{ data.cart.priceType }}
                        </td>
                      </tr>
                    </tbody>
                  </table> -->
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-button
                    style="margin-right: 15px;"
                    type="button"
                    variant="primary"
                    @click="deleteCartById(data)"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span class="align-middle"
                      >{{ data.cart.priceType }} Sepetimi Sil</span
                    >
                  </b-button>

                  <b-button
                    style="margin-top: 0px"
                    type="button"
                    variant="primary"
                    @click="getAddress(data)"
                  >
                    <feather-icon icon="SendIcon" class="mr-50" />
                    <span class="align-middle">Siparişi onayla</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card>
          <br />
          <br />
        </div>
      </div>
      <div id="cartEmpty" v-else>
        <b-card no-body class="mb-0">
          <div class="misc-wrapper">
            <div class="misc-inner p-2 p-sm-3">
              <div class="w-100 text-center">
                <h2 class="mb-1">
                  Sepetleriniz boş lütfen önce ürün seçiniz. 🛒
                </h2>

                <b-img
                  fluid
                  :src="imgUrl"
                  style="height: 250px"
                  alt="Sepetinizde ürün bulunamadı."
                />
                <br />
                <br />
                <div class="d-flex justify-content-center">
                  <b-button
                    style="margin-top: 0x"
                    type="button"
                    variant="primary"
                    :to="{ name: 'home' }"
                  >
                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                    <span class="align-middle">Ürünler</span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <b-modal
        size="lg"
        centered
        v-model="showAddress"
        title="Adres Seçimi"
        :hide-footer="true"
      >
        <div v-for="(data, index) in address" :key="index">
          <b-row>
            <b-col
              cols="8"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <div class="d-flex align-items-center">
                <b-avatar class="mr-2" variant="light-success">
                  <feather-icon
                    size="18"
                    icon="UserIcon"
                    v-if="data.type === 'PROFIL'"
                  />
                  <feather-icon
                    size="18"
                    icon="HomeIcon"
                    v-if="data.type === 'ADRESKARTI'"
                  />
                </b-avatar>
                <div>
                  <div class="font-weight-bolder">
                    {{ data.title }}
                  </div>
                  <div class="font-small-2 text-muted">
                    {{ data.address }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              cols="7"
              sm="4"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    {{ data.town }}
                  </div>
                  <div class="font-small-2 text-muted">
                    {{ data.district }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              cols="4"
              sm="2"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <b-button
                style="margin-top: 0x"
                type="button"
                v-b-modal.modal-center
                variant="primary"
                @click="selectAddress(data)"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Seç</span>
              </b-button>
            </b-col>
          </b-row>
          <br />
        </div>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BContainer,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BCardText,
  BCardTitle,
  BDropdownItem,
  BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BContainer,
    BCardText,
    ToastificationContent,
    BImg,
    BCol,
    BCard,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    BTable,
    BMedia,
    BCardTitle,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
  },
  data() {
    return {
      carts: [],
      address: [],
      tableColumns: [
        { key: "product.coverImgUrl", label: "Görsel" },
        { key: "product.partNo", label: "Parça No" },
        { key: "product.caption", label: "Parça Tanımı" },
        { key: "productUnit", label: "Miktar" },
        { key: "product.priceFormatted", label: "Birim Fiyat" },
        // { key: "discountPriceFormatted", label: "İskonto Tutarı" },
        { key: "totalPriceFormatted", label: "Toplam Fiyat" },
        { key: "actions", label: "İşlemler" }
      ],
      addressTableColumns: [
        { key: "icon", label: "" },
        { key: "title", label: "Adres" },
        { key: "select", label: "Seç" }
      ],
      show: false,
      downImg: require("@/assets/images/pages/undraw_empty_cart_co35.svg"),
      selectedAddressId: 0,
      selectedCartId: 0,
      showAddress: false,
      deleteCartId: 0
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.show = true;
      this.$http
        .get("/Cart/GetCart")
        .then(response => {
          this.carts = response.data.result.carts;
          this.show = false;
        })
        .catch(error => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    changeProductUnit(data) {
      this.show = true;
      this.$http
        .get(
          `/Cart/UpdateCartProduct?cartDetailId=${data.item.id}&productUnit=${data.value}`
        )
        .then(response => {
          this.init();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Güncelleme başarılı",
              icon: "CartIcon",
              text: response.data.message,
              variant: "success"
            }
          });
        })
        .catch(error => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    deleteCardProduct(data) {
      this.show = true;
      this.$http
        .get(`/Cart/RemoveCartProduct?cartDetailId=${data.item.id}`)
        .then(response => {
          this.init();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Silme başarılı",
              icon: "CartIcon",
              text: response.data.message,
              variant: "success"
            }
          });
        })
        .catch(error => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    getAddress(data) {
      this.selectedCartId = data.cart.id;
      this.show = true;
      this.$http
        .get("/User/GetDealerAddress")
        .then(response => {
          this.address = response.data.result;
          this.show = false;
          this.showAddress = true;
        })
        .catch(error => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    deleteCartById(data) {
      this.deleteCartId = data.cart.id;
      this.$http
        .get(`/Cart/DeleteCartById?cartId=${this.deleteCartId}`)
        .then(response => {
          this.init();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Silme başarılı",
              icon: "CartIcon",
              text: response.data.message,
              variant: "success"
            }
          });
        })
        .catch(error => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    selectAddress(data) {
      this.selectedAddressId = data.id;
      this.showAddress = false;
      this.show = true;
      this.$http
        .get(
          `/Cart/CreateOrder?cardId=${this.selectedCartId}&addressId=${this.selectedAddressId}`
        )
        .then(response => {
          this.init();
          this.$swal({
            title: response.data.message,
            text: `Evrak Numarası: ${response.data.result}`,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        })
        .catch(error => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    format(date) {
      var month = date.toLocaleString("tr-TR", { month: "short" });
      return date.getDate() + " " + month + " " + date.getFullYear();
    }
  },
  computed: {
    imgUrl() {
      return this.downImg;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
